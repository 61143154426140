import { SjStorage } from "../Utils/storage";
import { store } from "../redux/store";
import { setAuthorized } from "../redux/actions";
import { SjLogger } from "../Utils/logger";

const URL = 'https://stolpejaktenqa.azurewebsites.net/';
const BASE = async (endpoint: string, requiresAuth = true, method = 'GET', body = {}, ignoreCache = false) => {

  const headers: any = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'x-client-id': "stolpejakten"
  };

  if (requiresAuth) {
    headers.Authorization = 'Bearer ' + SjStorage.getItem('token');
  }

  const opts: any = { headers, method };
  if (method !== 'GET' && Object.keys(body).length) {
    opts.body = JSON.stringify(body)
  }

  const result = await fetch(URL + endpoint, opts);

  SjLogger.log('Result from ' + endpoint, method, result.status, result.ok);

  if (result.ok) {
    if (result.status === 204) {
      return { success: true };
    }
    const json = await result.json();
    /*
    if (method === 'GET') {
      dataCache[endpoint] = json;
    }*/
    SjLogger.log("Got data from " + endpoint);
    return json;
  }

  if (result.status >= 500) {
    const text = await result.text();
    if (text.indexOf("fetch")) {
      throw new Error("Kommunikasjonsproblemer. Prøv igjen om litt");
    }
  }

  if (result.status === 429) {
    throw new Error('For mange forsøk. Vent litt før du prøver igjen.');
  }

  if (result.status === 409) {
    const text = await result.text();
    const json = JSON.parse(text);
    throw new Error(json.message);
  }

  if (result.status === 400 || result.status === 404) {
    const text = await result.text();
    let error = 'En ukjent feil oppstod';
    try {
      const temp = JSON.parse(text);
      if (typeof temp !== 'undefined' && temp.message) {
        error = temp.message;
      }
      if (error === 'Network request failed') {
        error = 'Kunne ikke hente data';
      }
    } catch (e) {
      // let go past
    }
    throw new Error(error);
  }

  const isPing = endpoint === 'users/ping';

  if (result.status === 401 || result.status === 403) {
    SjStorage.removeItem('token');
    SjStorage.removeItem('me');
    SjStorage.removeItem('last_used_kommune');
    SjStorage.removeItem('family');
    SjStorage.removeItem('last_used_fylke');
    store.dispatch(setAuthorized(false));
    SjLogger.log('Unauthorized user');

    if (isPing) {
      SjLogger.log('Unauthorized user - Trying ping');
    }

    if (requiresAuth && !isPing) {
      const returnTo = window.location.pathname === '/logginn' ? '/profil' : window.location.pathname;
      window.location.href = '/logginn?returnUrl=' + (encodeURI(returnTo)) + "&re=1";
      store.dispatch(setAuthorized(false));
      return;
    }

    store.dispatch(setAuthorized(false));
    throw new Error('Unauthorized');
  }

  throw new Error('En ukjent feil oppstod.');
}

const DOWNLOAD = async (endpoint: string) => {
  const headers: any = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'x-client-id': "stolpejakten"
  };
  headers.Authorization = 'Bearer ' + SjStorage.getItem('token');
  const opts: any = { headers, method: "GET" };
  var result = await fetch(URL + endpoint, opts);
  return result.blob();
}

const GET = (endpoint: string, requiresAuth = true, noCache = false) =>
  BASE(endpoint, requiresAuth, 'GET', undefined, noCache);

const POST = (endpoint: string, body: any, requiresAuth = true) =>
  BASE(endpoint, requiresAuth, 'POST', body);

const DEL = (endpoint: string, body: any, requiresAuth = true) =>
  BASE(endpoint, requiresAuth, 'DELETE', body);

const PUT = (endpoint: string, body: any, requiresAuth = true) =>
  BASE(endpoint, requiresAuth, 'PUT', body);

const CLEARCACHE = (key: string) => {
  //delete dataCache[key];
}

function parseUrlParameters(q: any) {
  let querystring: any = q;
  querystring = querystring.substring(querystring.indexOf('?') + 1).split('&');
  const params: any = {};
  let pair;
  let d = decodeURIComponent;
  // march and parse
  for (var i = querystring.length - 1; i >= 0; i--) {
    pair = querystring[i].split('=');
    params[d(pair[0])] = d(pair[1] || '');
  }
  return params;
}


function upload(
  url: string,
  file: any,
  body: any = {},
) {
  const data: any = new FormData();
  Object.keys(body).forEach(k => {
    data.append(k, body[k]);
  });
  return new Promise((resolve, reject) => {
    data.append('file', file);
    const xhr = new XMLHttpRequest();
    xhr.open('POST', URL + url);
    const t = SjStorage.getItem('token');
    xhr.setRequestHeader('Authorization', `Bearer ${t}`);
    xhr.send(data);
    xhr.addEventListener('load', () => {
      try {
        const response = JSON.parse(xhr.responseText);
        return resolve({ data: { link: response.url } });
      } catch (e) {
        return reject('Kunne ikke laste opp fil');
      }
    });
    xhr.addEventListener('error', () => {
      try {
        const error = JSON.parse(xhr.responseText);
        return reject(error.message || 'Kunne ikke laste opp fil');
      } catch (e) {
        return reject('Kunne ikke laste opp fil');
      }
    });
  });
}

export const HttpHelper = {
  GET,
  POST,
  DEL,
  PUT,
  CLEARCACHE,
  parseUrlParameters,
  upload,
  DOWNLOAD
}